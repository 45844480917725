import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export class BeforeAfter extends Component {
    render() {
        let junkRemoval = false
        let dirtRock = false
        let greenWaste = false
        let propertyCleanout = false
        let commercialServices = false
        if (this.props.title.includes("Junk Removal")) {
            junkRemoval = true
        }
        else if (this.props.title.includes("Dirt & Rock")) {
            dirtRock = true
        }
        else if (this.props.title.includes("Green Waste")) {
            greenWaste = true
        }
        else if (this.props.title.includes("Property Cleanout")) {
            propertyCleanout = true
        }
        else if (this.props.title.includes("Commercial Services")) {
            commercialServices = true
        }
        return (
            <div className="bg-white pt-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-3 px-5 lg:px-10 gap-x-10 text-center lg:text-left">

                        <div className="lg:hidden mx-auto">
                            <h2 className="font-semibold text-xl">{this.props.title} Transformations</h2>
                            <p className="font-bold text-3xl py-3">From Cluttered To Clean!</p>
                            <p className="max-w-lg mx-auto pb-10 text-lg" dangerouslySetInnerHTML={{
                                __html: this.props.body,
                            }} />
                        </div>
                        <div className="lg:pt-2">
                            <GatsbyImage className="rounded-lg w-3/4 lg:w-full z-0" src="" image={this.props.imgBefore} title={this.props.ImgBeforeTitle} alt={this.props.ImgBeforeAlt} />
                            <p className="text-center py-2"><i>Before</i></p>
                        </div>
                        <div className="pt-5 pb-8 lg:pt-2">
                            <GatsbyImage className="rounded-lg w-3/4 lg:w-full z-0" src="" image={this.props.imgAfter} title={this.props.ImgAfterTitle} alt={this.props.ImgAfterAlt} />
                            <p className="text-center py-2"><i>After</i></p>
                        </div>
                        <div className={`hidden lg:block mx-auto ${junkRemoval ? 'lg:pt-4 xl:pt-2 2xl:pt-9' : dirtRock ? 'lg:pt-2 xl:pt-1 2xl:pt-9' : greenWaste ? 'lg:pt-2 xl:pt-2 2xl:pt-4' : propertyCleanout ? 'lg:pt-1 2xl:pt-4' : commercialServices ? 'lg:pt-1 2xl:pt-4' : 'xl:pt-10 2xl:pt-10'}`}>
                            <h2 className="font-semibold text-xl lg:text-lg xl:text-xl">{this.props.title} Transformations</h2>
                            <p className="font-bold text-3xl py-3 lg:text-2xl xl:text-3xl">From Cluttered To Clean!</p>
                            <p className="max-w-lg mx-auto pb-10 text-lg lg:text-base xl:text-lg" dangerouslySetInnerHTML={{
                                __html: this.props.body,
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from "react"

export class SectionTwo extends Component {
    render() {
        return (
            <div className="bg-bggray">
                <div className="container mx-auto pt-8 pb-10 px-5">
                    <div className="mx-auto text-center">
                        <span className="text-2xl font-bold ">{this.props.title}</span>
                        <p className="max-w-4xl mx-auto pt-5" dangerouslySetInnerHTML={{
                            __html: this.props.body,
                        }}>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}


import React, { Component } from "react"
import { Link } from "gatsby"

// TO USE: takes in two props: 1) buttonPage - page name to link to and 2) buttonText - text inside button
export class GreenButton extends Component {
    render() {
        return (
            <div>
                <Link to={this.props.buttonPage}>
                    <button className={`relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-150 ease-in-out ${this.props.whiteColor ? 'border-white bg-white text-green hover:shadow-white' : 'border-green bg-green text-white hover:shadow-green'}`} title={this.props.buttonText} alt={this.props.buttonText}>
                        <span>{this.props.buttonText}</span>
                    </button>
                </Link>
            </div>
        )
    }
}
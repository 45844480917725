import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { GreenButtonRedirect } from "./button-green-redirect"

export class ServicesMainSection extends Component {
    render() {
        let junkRemoval = false
        let dirtRock = false
        let greenWaste = false
        let propertyCleanout = false
        let commercialServices = false
        if (this.props.title.includes("Junk Removal")) {
            junkRemoval = true
        }
        else if (this.props.title.includes("Dirt & Rock")) {
            dirtRock = true
        }
        else if (this.props.title.includes("Green Waste")) {
            greenWaste = true
        }
        else if (this.props.title.includes("Property Cleanout")) {
            propertyCleanout = true
        }
        else if (this.props.title.includes("Commercial Services")) {
            commercialServices = true
        }
        return (
            <div>
                <div className="relative container mx-auto text-white">
                    <div className="absolute z-20 inset-x-0 py-10 md:py-24 px-10 text-center lg:text-left pl-10">
                        <h1 className="font-bold text-3xl md:text-5xl mx-auto">
                            {this.props.title}
                        </h1>
                        <div className="space-y-2 pt-5 md:pt-10 md:text-md lg:text-lg max-w-lg md:max-w-2xl mx-auto lg:mx-0" dangerouslySetInnerHTML={{
                            __html: this.props.description,
                        }}>
                        </div>
                        <div className="pl-1 pt-5 space-y-2 md:text-md lg:text-lg" dangerouslySetInnerHTML={{
                            __html: this.props.list,
                        }}>
                        </div>
                        <div className="mt-7">
                            <GreenButtonRedirect buttonText='Book Now' />
                        </div>
                        <div className="pt-7">
                            <p>Call/Text Us Today!</p>
                            <a className="text-lg md:text-xl font-bold hover:text-green" href="tel:916-591-3803" title="Call 1st Call Junk Haul Sacramento" alt="Call 1st Call Junk Haul Sacramento">(916)591-3803</a>
                        </div>
                    </div>
                </div>
                <GatsbyImage className={`w-screen ${junkRemoval ? 'h-[650px] sm:h-[540px] md:h-[640px]' : dirtRock ? 'h-[670px] sm:h-[510px] md:h-[680px]' : greenWaste ? 'h-[710px] sm:h-[560px] md:h-[700px]' : propertyCleanout ? 'h-[660px] sm:h-[540px] md:h-[640px] lg:h-[680px]' : commercialServices ? 'h-[720px] sm:h-[570px] md:h-[700px]' : 'h-[620px] md:h-[700px]'}`} src="" image={this.props.img} title={this.props.ImgTitle} alt={this.props.ImgAlt} />
            </div>
        )
    }
}

import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ServicesMainSection } from "../components/services-main-section"
import { SectionTwo } from "../components/services-section-two"
import { BeforeAfter } from "../components/before-after"
import { ServicesAbout } from "../components/services-about"

class ServicesTemplate extends React.Component {
    render() {
        const servicepage = this.props.data.contentfulServices
        const mainImage = getImage(servicepage.image)
        const imageBefore = getImage(servicepage.transformationsImageBefore)
        const imageAfter = getImage(servicepage.transformationsImageAfter)
        let title = ""
        let description = ""
        let keywords = []
        if (servicepage.title.includes("Junk Removal")) {
            title = servicepage.city + " - Junk Removal and Junk Hauling Services"
            description = "1st Call Junk Haul Sac is a local " + servicepage.city + " Junk Removal and Junk Hauling company offering professional Junk Removal, Junk Hauling, and Property Cleanout services with the best quality of work and the BEST prices in town. Find out why we are the best " + servicepage.city + " Junk Removal and Junk Hauling company and book an appointment today!"
            keywords = [
                servicepage.city + " Junk Removal",
                servicepage.city + " Junk Hauling",
            ]
        }
        else if (servicepage.title.includes("Dirt & Rock")) {
            title = servicepage.city + " - Dirt & Rock Removal Services"
            description = "1st Call Junk Haul Sac is a local " + servicepage.city + " Dirt & Rock Removal company offering professional Dirt & Rock Removal, Junk Removal and Junk Hauling services with the best quality of work and the BEST prices in town. Find out why we are the best " + servicepage.city + " Dirt & Rock Removal company and book an appointment today!"
            keywords = [
                servicepage.city + " Dirt & Rock Removal Services",
            ]
        }
        else if (servicepage.title.includes("Green Waste")) {
            title = servicepage.city + " - Green Waste Removal Services"
            description = "1st Call Junk Haul Sac is a local " + servicepage.city + "  Green Waste Removal company offering professional Green Waste Removal, Junk Removal and Junk Hauling services with the best quality of work and the BEST prices in town. Find out why we are the best " + servicepage.city + " Green Waste Removal company and book an appointment today!"
            keywords = [
                servicepage.city + " Green Waste Removal Services",
            ]
        }
        else if (servicepage.title.includes("Property Cleanout")) {
            title = servicepage.city + " - Property Cleanout Services"
            description = "1st Call Junk Haul Sac is a local " + servicepage.city + "  Property Cleanout company offering professional Property Cleanout, Junk Removal and Junk Hauling services with the best quality of work and the BEST prices in town. Find out why we are the best " + servicepage.city + " Property Cleanout company and book an appointment today!"
            keywords = [
                servicepage.city + " Property Cleanout Services",
            ]
        }
        else if (servicepage.title.includes("Commercial Services")) {
            title = servicepage.city + " - Commercial Services"
            description = "1st Call Junk Haul Sac is a local " + servicepage.city + "  Commercial Services company offering professional Commercial Services, Junk Removal and Junk Hauling services with the best quality of work and the BEST prices in town. Find out why we are the best " + servicepage.city + " Commercial Services company and book an appointment today!"
            keywords = [
                servicepage.city + " Commercial Services",
            ]
        }
        return (
            <Layout>
                <Seo
                    title={title}
                    description={description}
                    keywords={keywords}
                    location={"/" + servicepage.slug}
                    servicepage={servicepage}
                    isService={true}
                />
                <ServicesMainSection
                    title={servicepage.title}
                    description={servicepage.description.childMarkdownRemark.html}
                    list={servicepage.list.childMarkdownRemark.html}
                    img={mainImage}
                    ImgTitle={servicepage.image.title}
                    ImgAlt={servicepage.image.description}
                />
                <SectionTwo
                    title={servicepage.title}
                    body={servicepage.serviceDescription2.childMarkdownRemark.html}
                />
                <BeforeAfter
                    title={servicepage.title}
                    body={servicepage.transformationsBody.childMarkdownRemark.html}
                    imgBefore={imageBefore}
                    imgAfter={imageAfter}
                    ImgBeforeTitle={servicepage.transformationsImageBefore.title}
                    ImgBeforeAlt={servicepage.transformationsImageBefore.description}
                    ImgAfterTitle={servicepage.transformationsImageAfter.title}
                    ImgAfterAlt={servicepage.transformationsImageAfter.description}
                />
                <ServicesAbout
                    title={servicepage.title}
                    body={servicepage.aboutSectionBody.childMarkdownRemark.html}
                />
            </Layout>
        )
    }
}
export default ServicesTemplate
export const pageQuery = graphql`
query ContentfulServicesBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulServices( slug: {eq: $slug }) {
        title
        slug
        city
        description {
            childMarkdownRemark {
                html
            }
        }
        list {
            childMarkdownRemark {
                html
            }
        }
        image {
            gatsbyImageData (
               placeholder: NONE
               formats: [AUTO, WEBP, AVIF]
               quality: 90
               )
               title
               description
        }
        serviceDescription2 {
            childMarkdownRemark {
                html
            }
        }
        transformationsBody {
            childMarkdownRemark {
                html
            }
        }
        transformationsImageBefore {
            gatsbyImageData (
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 90
                height: 220
                )
                title
                description
        }
        transformationsImageAfter {
            gatsbyImageData (
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 90
                height: 220
                )
                title
                description
        }
        aboutSectionBody {
            childMarkdownRemark {
                html
            }
        }
    }
}
`
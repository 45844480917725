import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { GreenButton } from "./button-green"
import { GreenButtonRedirect } from "./button-green-redirect"

export class ServicesAbout extends Component {
    render() {
        return (
            <div className="bg-bggray">
                <div className="px-5 pt-10 md:pb-5">
                    <div className="max-w-5xl mx-auto">
                        <h3 className="font-bold text-2xl text-center">About Our {this.props.title} Prices</h3>
                        <div className="py-6 px-5 text-center max-w-4xl mx-auto">
                            <p dangerouslySetInnerHTML={{
                                __html: this.props.body,
                            }} />
                        </div>
                    </div>
                </div>

                <div className="container mx-auto flex px-5 pb-12 pt-5 md:pt-0">
                    <div className="grid space-y-5 mx-auto md:space-x-5 md:flex md:space-y-0">
                        <div className="max-w-lg mx-auto border shadow-xl rounded-lg flex p-5 items-center space-x-5 bg-white">
                            <div className="w-1/2">
                                <StaticImage src="../images/request.png" placeholder="none" className="rounded-t-lg z-0" title="Contact 1st Call Junk Haul Sacramento" alt="Contact 1st Call Junk Haul Sacramento" />
                            </div>
                            <div className="px-2">
                                <p className="text-xl font-bold text-green">Have Any Questions?</p>
                                <p className="text-sm pt-10">Get your questions, concerns or doubts answered within minutes!</p>
                                <div className="pt-10">
                                    <GreenButton buttonPage="/contact" buttonText="Contact Us" />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-lg mx-auto border shadow-xl rounded-lg flex p-5 items-center space-x-5 bg-green text-white">
                            <div className="w-1/2">
                                <StaticImage src="../images/calendar.png" placeholder="none" className="rounded-t-lg z-0" title="Book An Appointment" alt="Book An Appointment" />
                            </div>
                            <div className="px-2">
                                <p className="text-xl font-bold">Ready To Book A Service?</p>
                                <p className="text-sm pt-10">Reclaim your space and let 1st Call Junk Haul Sac do the heavy lifting!</p>
                                <div className="pt-10">
                                    <GreenButtonRedirect buttonText='Book Now' whiteColor={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
